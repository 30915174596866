import { useState } from "react";

const SelectWithOtherOption = ({ value, onChange, options, label, placeholder, name }) => {
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "Other") {
            setIsOtherSelected(true);
        } else {
            setIsOtherSelected(false);
            onChange(e); // Pass the value up to parent component
        }
    };

    const handleInputChange = (e) => {
        onChange(e); // Handle text input when "Other" is selected
    };

    const handleBlur = (e) => {
        if (e.target.value == "") {
            setIsOtherSelected(false);
        }
    }

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium">{label}</label>
            {isOtherSelected ? (
                <input
                    type="text"
                    value={value}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    placeholder={placeholder}
                    name={name}
                    onBlur={handleBlur}
                />
            ) : (
                <select
                    value={value}
                    onChange={handleSelectChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    name={name}
                >
                    <option value="">Select an option</option>
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                    <option value="Other">Other</option>
                </select>
            )}
        </div>
    );
};

export default SelectWithOtherOption;
