import React from "react";
import "../styles/loader.css";

const Loader = () => {
    return (
        <div className="loader-container fixed inset-0">
            <div className="bouncing-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
    );
}

export default Loader;