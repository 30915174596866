import React from 'react';
import SelectWithOtherOption from './SelectWithOtherOption';

const departments = [
  'IT Department',
  'Maintenance',
  'Engineering',
  'Safety',
  'Purchasing',];

const industryOptions = [
  'Oil & Gas',
  'Chemical',
  'Pharmaceuticals',
  'Manufacturing',
  'Telecommunications',
];

const FormComponent = ({ formData, setFormData, setNotificationProps }) => {

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        interestedProducts: Array.isArray(prevData.interestedProducts)
          ? [...prevData.interestedProducts, name]
          : [name],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        interestedProducts: prevData.interestedProducts.filter(
          (product) => product !== name
        ),
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form Data:', formData);
    await fetch('https://hook.eu2.make.com/qphoe9aoqz75bz2ntgb3je2un0bu3scf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      // .then((response) => response.json())
      .then((response) => {
        console.log('Webhook response:', response);
        setNotificationProps({ message: 'Success', isError: false, open: true })
      })
      .catch((error) => {
        console.error('Error sending data to webhook:', error);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg"
    >
      {/* Source of Lead Section */}
      <h3 className="text-xl font-semibold mb-4">Source of Lead</h3>
      <div className="mb-4">
        <select
          name="sourceOfLead"
          value={formData.sourceOfLead}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        >
          <optgroup>
            <option value="">Select Source of Lead</option>
          </optgroup>
          <optgroup label="Exhibition">
            <option value="ogat-2024">OGAT 2024</option>
            <option value="ogav-2024">OGAV 2024</option>
            <option value="vimox">VIMOX</option>
          </optgroup>
          <optgroup label="Sales Visit">
            <option value="sales-visit">Sales Visit</option>
          </optgroup>
        </select>
      </div>

      {/* Contact Information Section */}
      <h3 className="text-xl font-semibold mb-4">Contact Information (Auto-fill via OCR)</h3>
      <div className="mb-4">
        <label className="block text-sm font-medium">Full Name:</label>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium">Company Name:</label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium">Job Title:</label>
        <input
          type="text"
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium">Email Address:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium">Phone Number:</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium">Address:</label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium">Country/Region:</label>
        <input
          type="text"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>

      {/* Department Section */}
      <h3 className="text-xl font-semibold mb-4">Department</h3>
      <SelectWithOtherOption
        value={formData.department}
        onChange={handleInputChange}
        options={departments}
        placeholder={"Enter Department"}
        name={"department"}
      />

      <h3 className="text-xl font-semibold mb-4">Role in the Purchasing Process:</h3>
      <SelectWithOtherOption
        value={formData.role}
        onChange={handleInputChange}
        options={['Decision Maker', 'Influencer', 'End User']}
        placeholder={"Enter Role"}
        name={"role"}
      />

      {/* Interested Products Section */}
      <h3 className="text-xl font-semibold mb-4">Interested Products</h3>
      {['Conquest EX101', 'Conquest EX201', 'Conquest EX202', 'Conquest F2', 'Conquest F3', 'Conquest S25', 'Conquest S26', 'PTT360', 'WizyEMM', 'WizyVision'].map((product) => (
        <div className="mb-2" key={product}>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name={product}
              onChange={handleCheckboxChange}
              className="form-checkbox"
            />
            <span className="ml-2">{product}</span>
          </label>
        </div>
      ))}

      {/* Inquiry */}
      <div className="mb-4">
        <label className="block text-sm font-medium">Specific Inquiry/Request for Information:</label>
        <textarea
          name="inquiry"
          value={formData.inquiry}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>

      {/* Additional Information Section */}
      <h3 className="text-xl font-semibold mb-4">Additional Information</h3>

      <SelectWithOtherOption
        options={industryOptions}
        onChange={handleInputChange}
        selectedValues={formData.industry}
        label={"Industry/Vertical:"}
        placeholder={"Enter Industry"}
        name={"industry"}
      />

      {/* Interest Level */}

      <SelectWithOtherOption
        options={['Cold', 'Warm', 'Hot']}
        onChange={handleInputChange}
        selectedValues={formData.interestLevel}
        label={"Interest Level:"}
        placeholder={"Enter Level"}
        name={"interestLevel"}
      />

      {/* Purchase Timeline */}
      <SelectWithOtherOption
        options={['Immediate (within 1 month)', 'Short-term (1-3 months)', 'Mid-term (3-6 months)', 'Long-term (6+ months)']}
        onChange={handleInputChange}
        selectedValues={formData.purchaseTimeline}
        label={"Expected Purchase Timeline:"}
        placeholder={"Enter Timeline"}
        name={"purchaseTimeline"}
      />

      {/* Budget and Referral */}
      <div className="mb-4">
        <label className="block text-sm font-medium">Budget Range (Optional):</label>
        <input
          type="text"
          name="budget"
          value={formData.budget}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>

      {/* Sales In-Charge */}
      <div className="mb-4">
        <label className="block text-sm font-medium">Sales In-Charge</label>
        <input
          type="text"
          name="salesInCharge"
          value={formData.salesIncharge}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-md"
      >
        Submit
      </button>
    </form>
  );
};

export default FormComponent;
