import React, { useEffect, useState } from "react";

const NotificationModal = ({ message, isError, open, onClose }) => {
    const [visible, setVisible] = useState(open);

    useEffect(() => {
        if (!open || !message) {
            setVisible(false);
            return;
        }
        setVisible(true);
    }, [open, message]);

    if (!visible) return null;

    return (
        <div 
            id="notification-modal" 
            tabIndex="-1" 
            aria-hidden={!visible} 
            className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 transition-opacity ease-in-out duration-300">
            
            <div className="relative p-6 w-full max-w-md">
                <div className="relative bg-white rounded-xl shadow-lg dark:bg-gray-800 dark:text-white overflow-hidden">
                    
                    {/* Modal header */}
                    <div className={`flex justify-between items-center px-6 py-4 border-b ${isError ? 'bg-red-500' : 'bg-blue-500'} text-white`}>
                        <h3 className="text-lg font-semibold">
                            {isError ? 'Error' : 'Notification'}
                        </h3>
                        <button
                            onClick={() => {
                                setVisible(false);
                                if (onClose) onClose();
                            }}
                            className="p-1 rounded-full bg-white text-gray-500 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    {/* Modal body */}
                    <div className="px-6 py-4">
                        <p className={`text-base leading-relaxed ${isError ? 'text-red-700' : 'text-gray-700'} dark:${isError ? 'text-red-400' : 'text-gray-300'}`}>
                            {message}
                        </p>
                    </div>

                    {/* Modal footer */}
                    <div className="px-6 py-4 border-t bg-gray-50 dark:bg-gray-700">
                        <button
                            onClick={() => {
                                setVisible(false);
                                if (onClose) onClose();
                            }}
                            className={`w-full py-2.5 text-white font-medium rounded-lg shadow-lg transition-all ease-in-out duration-200 ${
                                isError ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
                            }`}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationModal;
