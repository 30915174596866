import React, { useState } from 'react';
import OCRForm from './components/OCRForm';
import FormComponent from './components/Form';
import Notification from './components/Notification';
import "./index.css";

function App() {
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    jobTitle: '',
    email: '',
    phoneNumber: '',
    address: '',
    country: '',
    department: '',
    interestedProducts: [],
    interestLevel: '',
    inquiry: '',
    industry: '',
    role: '',
    purchaseTimeline: '',
    budget: '',
    imageUrl: [],
    sourceOfLead: '',
  });

  const [NotificationProps, setNotificationProps] = useState({ message: '', isError: false, open: false });

  const onClose = () => {
    setNotificationProps(prev => ({ ...prev, open: false }));
    window.location.reload();
  }

  return (
    <div className="App">
      <OCRForm setFormData={setFormData} />
      <FormComponent setFormData={setFormData} formData={formData} setNotificationProps={setNotificationProps} />
      <Notification message={NotificationProps.message}
        isError={NotificationProps.isError}
        open={NotificationProps.open}
        onClose={onClose}
      />
    </div>
  );
}

export default App;
