import React, { useState } from 'react';
import Loader from './Loader';

function OCRForm({ setFormData }) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState([]);  // New state to hold image URL

    const handleFileChange = async (event) => {
        setLoading(true);
        const imageFile = event.target.files[0];
        if (imageFile) {
            const formData = new FormData();
            formData.append('image', imageFile);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/`, {
                    method: 'POST',
                    body: formData,
                });

                const responseData = await response.json();
                console.log('Response:', responseData);

                const extractedDetails = responseData.extracted_details;
                setFormData(prev => ({
                    ...prev,
                    fullName: extractedDetails.full_name || prev.fullName,
                    companyName: extractedDetails.company_name || prev.companyName,
                    jobTitle: extractedDetails.job_title || prev.jobTitle,
                    email: extractedDetails.email || prev.email,
                    phoneNumber: extractedDetails.phone_number || prev.phoneNumber,
                    address: extractedDetails.address || prev.address,
                    country: extractedDetails.country || prev.country,
                    imageUrl: [...(prev?.imageUrl ?? []), responseData.image_url],
                }));

                // Set the image URL state to display the uploaded image
                setImageUrl(prev => [...prev, responseData.image_url]);

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        setLoading(false);
    };

    return (
        <>
            <form className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Upload Image for OCR</h2>

                <div className="mb-6">
                    <label htmlFor="imageUpload" className="block text-sm font-medium text-gray-700 mb-2">
                        Upload Business Card Image:

                        {/* Display the uploaded image below the form */}

                        {imageUrl.length !== 0 ? (
                            <div className="mt-6 flex gap-1">
                                {imageUrl.map((url) => (
                                    <img key={url} src={url} alt="Uploaded" className="max-w-full h-32 rounded-lg shadow-md" />
                                ))}
                            </div>
                        ) : (
                            <div className="border border-gray-300 rounded-lg h-32 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-12 w-12 text-gray-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                            </div>
                        )}
                    </label>
                    <input
                        type="file"
                        id="imageUpload"
                        onChange={handleFileChange}
                        accept="image/*"
                        className="w-full border border-gray-300 rounded-lg px-4 py-2 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
            </form>

            {loading && <Loader />}
        </>
    );
}

export default OCRForm;
